<template>
  <div
    class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
    style="max-width:350px;"
    :style="
      getCardBackground(pro.style) +
      getCardBorderCSS(pro.style) +
      getPagePadding(pro.style,isDesktopView) +
      getFontFamily(pro.style) +
      ListProductMaxWidth(
        isDesktopView ? pro.productsPerPage : pro.productsPerPageMobile
      )
    "
  >
    <div
      style="
        position: absolute;
        top: -2%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
      "
      class="d-flex justify-content-center"
      v-if="pro.showProductRank && index <= productsLength"
    >
      <p
        style="text-align: center"
        :style="getProductRankCss(pro.style)"
        v-if="
          pro.productRankOption[index] &&
          pro.productRankOption[index].text != ''
        "
      >
        <span>{{ pro.productRankOption[index].text }}</span>
      </p>
    </div>

    <div>
      <div
        v-if="
          showImage(
            getProductInfoDetail(pro.useProductVariant, product, 'image')
          )
        "
        style="height: 100%"
      >
        <div
          v-if="
            checkFileType(
              getProductInfoDetail(pro.useProductVariant, product, 'image')
            ) == 'video'
          "
          :style="ListImageAspectRatio(pro.style)"
        >
          <video
            ref="videoRef"
            :src="`${getProductInfoDetail(
              pro.useProductVariant,
              product,
              'image'
            )}`"
            :style="getImageFit(pro.style) + getImageTopBorderCSs(pro.style)"
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              height: 100%;
              width: 100%;
            "
            controls
          ></video>
        </div>
        <div v-else :style="ListImageAspectRatio(pro.style)">
          <LazyImage
            v-if="
              getProductInfoDetail(
                pro.useProductVariant,
                product,
                'image'
              ).startsWith('upload')
            "
            :src="
              '/' +
              getProductInfoDetail(pro.useProductVariant, product, 'image')
            "
            alt=""
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              height: 100%;
              width: 100%;
            "
            :style="getImageFit(pro.style) + getImageTopBorderCSs(pro.style)"
          />
          <LazyImage
            v-else
            :src="getProductInfoDetail(pro.useProductVariant, product, 'image')"
            alt=""
            style="
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              height: 100%;
              width: 100%;
            "
            :style="getImageFit(pro.style) + getImageTopBorderCSs(pro.style)"
          />
        </div>
      </div>
    </div>
    <div class="mt-2 flex-grow-1" :style="getPaddingCss(pro.style)">
      <div class="d-flex flex-column h-100">
        <!-- Product Title -->
        <div >
          <p class="m-0 mb-2"  :style="getTitleStyles(pro.style)">
            {{ getProductInfoDetail(pro.useProductVariant, product, "title") }}
          </p>
          <!-- Rating Div start -->
          <div
            v-if="product.rating && isJudgeMeInstalled"
            class="Review mb-1"
            v-html="product.rating"
            :style="getPriceColor(pro.style)"
          ></div>
          <!-- Rating Div end -->
        </div>
        <!-- Product Price , features & buy button -->
        <div class="d-flex flex-column justify-content-between flex-grow-1">
          <div class="">
            <div v-if="!pro.hideDescription || pro.useBulletDescription">
              <div
                class="d-flex align-items-start"
                :style="
                  limitProductDescription(
                    pro.limitDescription,
                    product.readMore
                  )
                "
              >
                <div
                  v-if="
                    pro.useBulletDescription &&
                    product.bullet_description &&
                    product.bullet_description.length
                  "
                >
                  <div
                    v-for="(bulletPoint, index2) in product.bullet_description"
                    :key="index2"
                  >
                    <div
                      class="d-flex align-items-start mb-1"
                      :style="getBulletStyles(pro.style)"
                    >
                      <div class="mr-3">
                        <i class="fa" :class="pro.selectedBulletIcon"></i>
                      </div>
                      <span style="text-align: start">{{ bulletPoint }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-100 mb-2" :style="`font-family:${pro.style.fontFamily};`" v-if="!pro.hideDescription" v-html="product.description"></div>
              </div>
              <div
                class=""
                v-if="
                  pro.limitDescription &&
                  product.description &&
                  product.description.split(' ').join('').length > 200 &&
                  false
                "
              >
                <button
                  class="btn"
                  :class="product.readMore ? 'mt-2' : 'mt-2'"
                  :style="getPriceColor(pro.style)"
                  @click="product.readMore = !product.readMore"
                >
                <span>{{ product.readMore ?  translateText("Less info") : translateText("More info") }}</span>
                 
                </button>
              </div>
            </div>
          </div>

          <div >
            <div
              class="d-flex align-items-center"
              :style="'justify-content:' + pro.style.textAlign + ';'"
            >
              <p
                v-if="product.price && !isHidePrice"
                :style="getPriceColor(pro.style)"
                class="m-0 p-0 mr-2"
              >
                <span
                  v-if="CurrencySign && currencyPos == 'left'"
                  :class="currencySpace ? 'mr-1' : null"
                  >{{ getProductCurrency(product) }}</span
                >{{
                  getProductInfoDetail(pro.useProductVariant, product, "price")
                }}<span
                  v-if="CurrencySign && currencyPos == 'right'"
                  :class="currencySpace ? 'ml-1' : null"
                  >{{ getProductCurrency(product) }}</span
                >
              </p>
              <p
                v-if="
                  product.compare_at_price &&
                  product.price !== product.compare_at_price &&
                  !isHidePrice
                "
                :style="getPriceColor(pro.style)"
                class="m-0 p-0"
              >
                <s>
                  <span
                    v-if="CurrencySign && currencyPos == 'left'"
                    :class="currencySpace ? 'mr-1' : null"
                    >{{ getProductCurrency(product) }}</span
                  >{{ product.compare_at_price
                  }}<span
                    v-if="CurrencySign && currencyPos == 'right'"
                    :class="currencySpace ? 'ml-1' : null"
                    >{{ getProductCurrency(product) }}</span
                  >
                </s>
              </p>
            </div>

            <div class="mt-2" v-if="product.detail_link">
              <div
                class="pb-2"
                v-if="pro.useProductVariant && product && product.variants.length > 0"
              >
                <VaraintSelectComp :pro="pro" :product="product" />
              </div>

              <button
                class="ButtonHover"
                v-if="pro.useAddToCart"
                :style="getProductButtonCss(pro.style)"
                :disabled="disableAddtoCartBtn"
                @click="AddToCart(product, index)"
              >
                <span v-if="!product.loading">{{
                  pro.useAddToCartText || "Add to Cart"
                }}</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
              <button
                class="ButtonHover"
                v-else
                :style="getProductButtonCss(pro.style)"
                @click="goToURLClicked(product.detail_link, product.id)"
              >
                <span v-if="!product.loading"> {{ pro.buttonText }}</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>

import cssFunctionMixin from "../../../customize/mixin/cssFunction";
export default {
  components:{
  VaraintSelectComp: () =>
      import(
        /* webpackChunkName: "VaraintSelectComp" */ "../../VaraintSelectComp.vue"
      )
},
  mixins: [cssFunctionMixin],
  props: [
    "pro",
    "index",
    "isTest",
    "isDesktopView",
    "productsLength",
    "product",
    "displayProductsLength",
    "isJudgeMeInstalled",
    "isHidePrice",
    "CurrencySign",
    "currencyPos",
    "currencySpace",
    "disableAddtoCartBtn",
    "AddToCart",
    "goToURLClicked",
    "getProductInfoDetail",
    "checkFileType",
    "getImageFit",
    "getImageTopBorderCSs",
    "getTitleStyles",
    "getPriceColor",
    'checkToalettsitsarClient'
  ],
  methods: {

    getProductCurrency(product){
      if(this.checkToalettsitsarClient && product.price_symbol){
        return product.price_symbol
      } 
      return this.CurrencySign
    },


    showImage(image) {
      return !(
        image.includes("default_start_page.png") ||
        image.includes("/images/image_placeholder.png")
      );
    },
    getCardBackground(property) {
      return "background-color:" + property.backgroundColor + ";";
    },
    getCardBorderCSS(property) {
      return (
        " border-radius:" +
        property.borderRadius +
        "px; border:" +
        property.borderSize +
        "px solid " +
        property.borderColor +
        ";"
      );
    },
    getPagePadding(property, isDesktopView) {
  const getPaddingValue = (desktopValue, mobileValue) =>
    isDesktopView || mobileValue === undefined ? desktopValue : mobileValue;

  const paddingTop = getPaddingValue(property.pagePaddingTop, property.pageMobilePaddingTop);
  const paddingRight = getPaddingValue(property.pagePaddingRight, property.pageMobilePaddingRight);
  const paddingBottom = getPaddingValue(property.pagePaddingBottom, property.pageMobilePaddingBottom);
  const paddingLeft = getPaddingValue(property.pagePaddingLeft, property.pageMobilePaddingLeft);

  return `; text-align: ${property.textAlign}; padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px; `;
},
    getFontFamily(property) {
      return "font-family:" + property.fontFamily + ";";
    },
  },
};
</script>


